import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { environment } from '../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

// Original code
// @Injectable({
//   providedIn: 'root'
// })
// export class TokenService {
  
//   constructor(private msalService: MsalService) {}  
//   accessToken = ''

//     async setAccessToken() {
//         try {
//            this.msalService.acquireTokenSilent({
//               scopes: [`${environment.scopeUri}/${environment.scope}`],
//               account: this.msalService.instance.getAllAccounts()[0]
//             }).subscribe(r => {
//               this.setToken = r.accessToken
//               return r.accessToken
//             });
//         } catch (error) {
//             console.error('Error acquiring token:', error);
//         }
//     }

//     private set setToken(val: string){
//         this.accessToken = val
//     }

//     get getToken(): string {
//       return this.accessToken
//     }
// }

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private accessToken = '';
  private userInfo = new BehaviorSubject<any>(null);
  origin = environment.apiOrigin

  constructor(private msalService: MsalService, private http: HttpClient) {}

  async setAccessToken() {
    try {
      this.msalService
        .acquireTokenSilent({
          scopes: [`${environment.scopeUri}/${environment.scope}`],
          account: this.msalService.instance.getAllAccounts()[0],
        })
        .subscribe((r) => {
          this.setToken = r.accessToken;
          this.fetchUserInfo(); // Fetch user info when token is set
        });
    } catch (error) {
      console.error('Error acquiring token:', error);
    }
  }

  private set setToken(val: string) {
    this.accessToken = val;
  }

  get getToken(): string {
    return this.accessToken;
  }

  get getUserInfo(): Observable<any> {
    return this.userInfo.asObservable();
  }

  private fetchUserInfo() {
    if (!this.accessToken) {
      console.error('No access token found. Unable to fetch user info.');
      return;
    }

    const headers = { Authorization: `Bearer ${this.accessToken}` };
    this.http.get(`${this.origin}/user-info`, { headers }).subscribe(
      (info) => this.userInfo.next(info),
      (error) => console.error('Error fetching user info:', error)
    );
  }
}