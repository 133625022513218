import { Component, OnInit, HostListener, Input } from '@angular/core';
import { MsalService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import { filter } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { NgxSpinnerService } from "ngx-spinner";
import { AppComponent } from '../app/app.component';

interface Message {
  text: string;
  isUser: boolean;
  isAbbreviatedTitle?: boolean;
  title?: string;
  content?: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {
  query: string = '';
  queryId: string = '';
  response: string = '';
  questionDisplay: string = '';
  isFormVisible: boolean = true;
  showFeedback: boolean = false;
  showComment: boolean = false;
  comment: string = '';
  origin = environment.apiOrigin
  loginDisplay = false
  messages: Message[] = []; // Updated type
  specialBotResponse: string = ``;
  showSpecialButtons: boolean = false;
  mostRecentBotMessageIndex: number | null = null;
  matchingObjects: any[] = [];
  quotedTexts: any[] = [];
  oldContractObjects: any[] = [];
  filteredMatchingObjects: any[] = []; // for implementation dates
  showModal: boolean = false; // Modal visibility
  modalData: any = {}; // Data for the modal display
  showScore: boolean = false;
  score: number | null = null;
  scoreComment: string = '';
  scoreError: string | null = null;
  userFirstName: string = '';
  userName: string = '';
  welcomeMessage: string = '';
  allowedUsers: string[] = ['Matthew Monahan', 'Kandi Doming', 'Brenda Burkham', 'Jeanna Christopher', 'Ryne Ray', 'Jana Fuller', 'Belstephen JeDai', 'Maria Almanza', 'Lexi Taylor', 'Cathy Cox']

  constructor(private http: HttpClient, 
    private msalBroadcastService: MsalBroadcastService, 
    private spinner: NgxSpinnerService,
    private appComponent: AppComponent) { }

  ngOnInit(): void {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        console.log(result);
      });
      this.http.get(`${this.origin}/clear`, { responseType: 'text' }).subscribe({
        next: (response) => console.log('Success:', response),
        error: (err) => console.error('Error:', err)
      });

      this.fetchUserInfo();
  }


  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: any) {
    // Send a request to clear the conversation history on the server when the page is refreshed
    this.http.get('/clear').subscribe();
  }

  // highlight version
  onSubmit(event?: Event) {
    this.specialBotResponse = '';
    if (event) {
      event.preventDefault(); // Prevent default Enter key behavior
    }
    const query = this.query;
    this.showFeedback = false;
    this.messages.push({ text: query, isUser: true });
    this.messages.push({ text: 'loading', isUser: false });
    // this.spinner.show();
    const body = new URLSearchParams({ query: this.query });
  
    this.http.post<any>(`${this.origin}/question`, `${body}`, { responseType: 'json' })
      .subscribe(data => {
        this.messages.pop(); // Remove loading message
        // this.spinner.hide();
  
        this.response = data.response;
        this.matchingObjects = data.matching_objects;
  
        // Store the query ID for feedback submission
        if (data.query_id) {
          this.queryId = data.query_id;  // Save query_id from response
        } else {
          console.warn('Warning: query_id is missing from response.');
        }

        // Store quoted_text in a separate variable
        this.quotedTexts = data.quoted_text || [];
  
        this.filteredMatchingObjects = this.matchingObjects.filter(
          (obj) => obj.implementation_date && obj.implementation_date.length > 0
        );
  
        let implementationDates: string[] = [];
        if (this.filteredMatchingObjects.length > 0) {
          implementationDates = this.filteredMatchingObjects.flatMap(obj => obj.implementation_date);
  
          // Join dates inline with commas
          const formattedDates = implementationDates.join('\n');
          this.response += `\n\nReview context for Implementation notes:\n\n${formattedDates}`;
  
          console.log(implementationDates);
          // Highlight dates in the chat response
          this.response = this.colorizeDates(this.response, implementationDates);
        }
  
        this.messages.push({ text: this.response, isUser: false });
        this.mostRecentBotMessageIndex = this.messages.length - 1;
  
        this.displayAbbreviatedTitles();
        this.showFeedback = true;

        // Hide form after submit
        this.hideForm();
  
        this.oldContractObjects = data.old_contract_objects || [];
        if (this.oldContractObjects.length > 0) {
          this.handleOldContractObjects(query, this.oldContractObjects);
        }
      });
  
    this.query = '';

    // reset text area size after submit
    const textareaElement = document.querySelector('textarea[name="query"]') as HTMLTextAreaElement;
    if (textareaElement) {
      textareaElement.style.height = 'auto';  // Resets to original size
    }
  }
  

  onSpecialSubmit(): void {
    console.log('Special Submit button clicked by:', this.userName);
    this.specialBotResponse = '';
    const query = this.query;
    this.showFeedback = false;
    // this.messages.push({ text: query, isUser: true });
    this.messages.push({ text: 'loading', isUser: false });
    // this.spinner.show();
    const body = new URLSearchParams({ query: this.query });
    this.query = '';

    // Sending query to backend
    this.http.post<any>(`${this.origin}/question-assist`, `${body}`, { responseType: 'json' })
      .subscribe(data => {
        this.messages.pop(); // Remove loading message
        // this.spinner.hide();
        
        // Set the main response
        this.specialBotResponse = data.response[0];
        this.showSpecialButtons = true;
        
        // Store and display `abbreviated_title` from matching objects

        // Reset query input
        //this.query = '';
        this.showFeedback = false;
      });

  }

  onSubmitC2012(query: string, oldContractObjects: any[], event?: Event) {
    if (event) {
        event.preventDefault(); // Prevent default Enter key behavior
    }

    this.specialBotResponse = '';
    this.showFeedback = false;

    // Prepare the request body
    const context = JSON.stringify(oldContractObjects); // Convert objects to JSON
    const body = new URLSearchParams({ query: query, context: context });

    this.messages = [];

    // Add loading message
    this.messages.push({ text: query, isUser: true });
    this.messages.push({ text: 'loading', isUser: false });
    // this.spinner.show();

    // Send POST request to /questionC2012 endpoint
    this.http.post<any>(`${this.origin}/questionC2012`, `${body}`, { responseType: 'json' })
        .subscribe(data => {
            this.messages.pop(); // Remove loading message
            this.spinner.hide();

            // Process the response
            this.response = `With reference to C2012: ${data.response}`;
            this.messages.push({ text: this.response, isUser: false });
            this.mostRecentBotMessageIndex = this.messages.length - 1;

            // Store the query ID for feedback submission
            if (data.query_id) {
              this.queryId = data.query_id;  // Save query_id from response
            } else {
              console.warn('Warning: query_id is missing from response.');
            }

            // Handle matching objects
            this.matchingObjects = data.matching_objects;
            this.quotedTexts = data.quoted_text || [];
            this.displayAbbreviatedTitles();

            // Reset input and show feedback
            this.query = '';
            this.showFeedback = true;
        }, error => {
            // Handle errors
            console.error('Error during onSubmitC2012:', error);
            // this.spinner.hide();
            this.messages.pop(); // Remove loading message
            this.messages.push({ text: 'An error occurred. Please try again.', isUser: false });
        });
  }

  // Prevent new line with enter key
  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      event.preventDefault();  // Prevents the new line

      // Check if the input is empty
      if (!this.query || this.query.trim() === '') {
        console.log('Query is empty. Submission prevented.');
        return; // Do not trigger form submission
      }

      this.onSubmit();  // Trigger form submission instead
    }
  }  

  handleOldContractObjects(query: string, oldContractObjects: any[]): void {
    console.log('Handling old contract objects:', this.oldContractObjects);
    // Implement your desired logic here
    // alert('The following contains references to this section of the old contract: ');
    // Check if oldContractObjects is an array and map the titles
    const titles = Array.isArray(this.oldContractObjects)
        ? this.oldContractObjects.map(obj => obj.abbreviated_title).join(', ')
        : 'No references available';

    // Show the alert with the dynamically included titles
    //alert(`The following contains references to this section of the old contract: ${titles} Please read the Implementation Notes`);
    const message = `For this question it is recomended that you reference section ${titles} of C2012. As the related sections in the current CBA have yet to be implemented. Press Ok to proceed or press Cancel to decline.`;
  
    // Show confirmation dialog
    const userConfirmed = confirm(message);

    if (userConfirmed) {
      console.log('User wants to proceed')
      this.onSubmitC2012(query, oldContractObjects);
    } else {
      console.log('User chose not to proceed.');
    }

  }


  handleSubmitSpecialResponse(): void {
    this.query = this.specialBotResponse; // Set the query to the special response
    this.onSubmit(); // Trigger the onSubmit function
    this.clearSpecialResponse(); // Clear the special response and buttons
  }

  handleEditSpecialResponse(): void {
    this.query = this.specialBotResponse; // Paste the response into the textarea
    this.clearSpecialResponse(); // Clear the special response and buttons
  }

  clearSpecialResponse(): void {
    this.specialBotResponse = ''; // Clear the response
    this.showSpecialButtons = false; // Hide the buttons
  }

  // change color of implementation dates
  colorizeDates(text: string, dates: string[]): string {
    if (!dates || dates.length === 0) return text;
    dates.forEach(date => {
      text = text.replace(date, `<span class="red-text">${date}</span>`);
    });
    return text;
  }
  
  // best highlight so far
  highlightQuotedText(text: string, quotedTexts: string[]): string {
    if (!quotedTexts || quotedTexts.length === 0) return text;
  
    quotedTexts.forEach(quotedText => {
      // Escape special characters for regex safety
      const escapedText = quotedText.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  
      // Use regex to highlight only the exact phrase inside quotation marks
      const regex = new RegExp(`(${escapedText})`, 'gi');  // Case-insensitive match
      text = text.replace(regex, `<span class="highlight-text">$1</span>`);
    });
  
    return text;
  }

  // not ready to delete
  // highlightQuotedText(text: string, quotedTexts: string[]): string {
  //   if (!quotedTexts || quotedTexts.length === 0) return text;
  
  //   quotedTexts.forEach(quotedText => {
  //     // Trim the quoted text to remove leading/trailing spaces
  //     const trimmedText = quotedText.trim();
  
  //     // Escape special characters for regex safety
  //     const escapedText = trimmedText.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  
  //     // Regex to match only the exact phrase with optional leading/trailing non-breaking characters
  //     const regex = new RegExp(`(?<!\\S)(${escapedText})(?!\\S)`, 'g');  // Matches only whole words
  //     text = text.replace(regex, `<span class="highlight-text">$1</span>`);
  //   });
  
  //   return text;
  // }
  
  


  openModal(matchingObject: any) {
    console.log('Modal opened for:', matchingObject);
    const implementationDates = this.filteredMatchingObjects.flatMap(obj => obj.implementation_date);
  
    let formattedContent = this.colorizeDates(matchingObject.content, implementationDates);

    // Fetch `quoted_text` from `matchingObject`
    if (this.quotedTexts.length > 0) {
      console.log("Highlighting quoted texts:", this.quotedTexts);
      formattedContent = this.highlightQuotedText(formattedContent, this.quotedTexts);  // Highlight quoted texts in modal
    } else {
      console.log("No quoted text found.");
    }

    formattedContent = formattedContent.replace(/\[|\]/g, '');
  
    // Add forced line breaks to separate paragraphs cleanly before replacements
    formattedContent = formattedContent.replace(/\n/g, ' ');
  
    // Step 1: Replace "Section X.Y.Z" and "FAR X.Y.Z" with placeholders
    const placeholders: { [key: string]: string } = {};
    let placeholderIndex = 0;
  
    formattedContent = formattedContent.replace(/(Section|FAR)\s+(\d+(?:\.[A-Za-z0-9]+)+)/g, (match) => {
      const key = `PLACEHOLDER_${placeholderIndex++}`;
      placeholders[key] = match; // Save the original reference
      return key; // Replace with placeholder
    });
  
    // Step 2: Run list marker regex (e.g., "1.", "a.", "2)", "b)")
    formattedContent = formattedContent.replace(/(?:^|[\s“”—])(\d{1,3})\.\s(.+?)(?=\s\d{1,3}\.| [a-z]\.| \d{1,3}\)| [a-z]\)|$)/g, '</div><div class="indent-0">$1. $2</div>');  // Numbers with period (e.g., "1.")
    formattedContent = formattedContent.replace(/(?:^|[\s“”—])([a-z])\.\s(.+?)(?=\s\d{1,3}\.| [a-z]\.| \d{1,3}\)| [a-z]\)|$)/g, '</div><div class="indent-1">$1. $2</div>');  // Lowercase letters (e.g., "a.")
    formattedContent = formattedContent.replace(/(?:^|[\s“”—])(\d{1,3})\)\s(.+?)(?=\s\d{1,3}\.| [a-z]\.| \d{1,3}\)| [a-z]\)|$)/g, '</div><div class="indent-2">$1) $2</div>');  // Numbers with parentheses (e.g., "2)")
    formattedContent = formattedContent.replace(/(?:^|[\s“”—])([a-z])\)\s(.+?)(?=\s\d{1,3}\.| [a-z]\.| \d{1,3}\)| [a-z]\)|$)/g, '</div><div class="indent-3">$1) $2</div>');  // Lowercase letters with parentheses (e.g., "a)")
  
    // Step 3: Replace placeholders back with original references
    formattedContent = formattedContent.replace(/PLACEHOLDER_(\d+)/g, (_, index) => placeholders[`PLACEHOLDER_${index}`]);
  
    // Remove unnecessary leading `</div>` if present
    formattedContent = formattedContent.replace(/^<\/div>/, '');
  
    console.log(formattedContent);
  
    this.modalData = {
      ...matchingObject,
      content: formattedContent,
    };
    this.showModal = true;
  }

  
  
  
  // previous Method to open modal with matching object data
  // openModal(matchingObject: any) {
  //   console.log('Modal opened for:', matchingObject); // Debugging line
  //   this.modalData = matchingObject;
  //   this.showModal = true;
  // }

  // Method to open modal with matching object data and color implementation dates
  // openModal(matchingObject: any) {
  //   const implementationDates = this.filteredMatchingObjects.flatMap(obj => obj.implementation_date);
  //   // const coloredContent = this.colorizeDates(matchingObject.content, implementationDates);
  //   let formattedContent = this.colorizeDates(matchingObject.content, implementationDates);

  //   formattedContent = formattedContent.replace(/ (\d{1,2})\.\s/g, '<br><br>$1. ');

  //   this.modalData = {
  //     ...matchingObject,
  //     content: formattedContent,
  //   };
  //   this.showModal = true;
  // }
  
  

  // Method to close modal
  closeModal() {
      this.showModal = false;
      this.modalData = {};
  }

  displayAbbreviatedTitles(): void {
    this.matchingObjects.forEach(obj => {
        if (obj.abbreviated_title) {
            this.messages.push({
                text: obj.abbreviated_title,
                isUser: false,
                isAbbreviatedTitle: true, // Mark as abbreviated title for clickable functionality
                title: obj.title,
                content: obj.content
            });
        }
    });
    this.mostRecentBotMessageIndex = this.messages.length - 1;
  } 

  // Method to hide form
  hideForm() {
    this.isFormVisible = false;
  }

  showForm() {
    this.isFormVisible = true;
  }

  // Maybe revisit this later. Refreshing the page just calls all the right clear functions so its easier for now.
  resetApplication() {
    window.location.reload();
  }

  // Send Feedback by searching for the query in DB
  // sendFeedback(isPositive: boolean | null = null, score: number | null = null) {
  //   this.showFeedback = false;
  //   // Validate score if it's provided
  //   if (score !== null && (score < 0 || score > 10)) {
  //       alert('Score must be between 0 and 10.');
  //       return; // Stop execution if the score is invalid
  //   }

  //   this.spinner.show();
  //   const mostRecentUserMessage = this.messages.slice().reverse().find(msg => msg.isUser)?.text || '';

  //   const feedbackType = isPositive === null ? 'thinking' : (isPositive ? '1' : '0');
  //   const body = new URLSearchParams({
  //       query: mostRecentUserMessage,
  //       feedback: feedbackType,
  //       comment: this.comment, // Shared comment variable
  //       ...(score !== null && { score: score.toString() }) // Include score if applicable
  //   });

  //   this.http.post(`${this.origin}/feedback`, `${body}`, { responseType: 'text' })
  //       .subscribe(() => {
  //           this.spinner.hide();
  //           alert('Feedback received! Thank you.');
  //           this.showFeedback = false;
  //           this.showComment = false;
  //           this.comment = ''; // Reset the shared comment variable
  //           this.showScore = false;
  //           this.score = null;
  //       }, error => {
  //           this.spinner.hide();
  //           console.error('Error sending feedback:', error);
  //           alert('Error sending feedback. Please try again.');
  //       });
  // }

  // Send Feedback by searching for the query id in DB
  sendFeedback(isPositive: boolean | null = null, score: number | null = null) {
    this.showFeedback = false;

    // Validate score if it's provided
    if (score !== null && (score < 0 || score > 10)) {
        alert('Score must be between 0 and 10.');
        return; // Stop execution if the score is invalid
    }

    this.spinner.show();
    
    // Ensure we have a valid query ID
    if (!this.queryId) {
        console.error("Error: Query ID is missing. Cannot send feedback.");
        alert("Error: Query ID is missing. Please ask a question first before submitting feedback.");
        this.spinner.hide();
        return;
    }

    const feedbackType = isPositive === null ? 'thinking' : (isPositive ? '1' : '0');

    // Construct the request body
    const body = new URLSearchParams({
        query_id: this.queryId,  // Send query_id instead of query text
        feedback: feedbackType,
        comment: this.comment, // Shared comment variable
        ...(score !== null && { score: score.toString() }) // Include score if applicable
    });

    this.http.post(`${this.origin}/feedback`, body.toString(), { responseType: 'text' })
        .subscribe(() => {
            this.spinner.hide();
            alert('Feedback received! Thank you.');
            this.showFeedback = false;
            this.showComment = false;
            this.comment = ''; // Reset the shared comment variable
            this.showScore = false;
            this.score = null;
        }, error => {
            this.spinner.hide();
            console.error('Error sending feedback:', error);
            alert('Error sending feedback. Please try again.');
        });
  }




  showCommentSection() {
    this.showComment = true;
    this.showFeedback = false;
  }

  submitComment() {
    this.sendFeedback(false);
  }

  autoResize(textareaEvent: Event) {
    const textarea = textareaEvent.target as HTMLTextAreaElement;
    if (textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = textarea.scrollHeight + 'px';
    }
  }

  ///////////////////////////////
  // Method to show the score form
  showScoreForm() {
    this.showScore = true;
    this.scoreError = null; // Clear any previous errors
    this.showFeedback = false;
  }

  // Validate the score input
  validateScore() {
    if (this.score === null || this.score < 0 || this.score > 10) {
        this.scoreError = 'Score must be a number between 1 and 10.';
    } else {
        this.scoreError = null; // Clear the error if the input is valid
    }
  }

  // Method to submit the score and comment
  submitScore() {
    this.validateScore();
    if (!this.scoreError) {
        const feedbackData = {
            score: this.score,
            comment: this.scoreComment,
        };
        console.log('Feedback submitted:', feedbackData);
        // Add logic to send feedbackData to the backend
        this.showScore = false; // Hide the form after submission
        this.score = null;
        this.scoreComment = '';
    }
  }

  //Fetch User Info
  fetchUserInfo() {
    this.http.get(`${this.origin}/user-info`).subscribe({
      next: (info: any) => {
        console.log(info.user_name)
        this.userFirstName = info.user_name.split(' ')[0]; // Extract first name
        this.userName = info.user_name
        this.setWelcomeMessage();
      },
      error: (err) => {
        console.error('Error fetching user info:', err);
        // this.userFirstName = 'Guest'; // Fallback
        // this.userName = 'Guest'
        // this.setWelcomeMessage();

        if(err.status === 401)
        {
          const unauthorisedLogout = window.alert(
            'Logged In User is UnAuthorised. Please log out and log back in with proper credentials. Click "OK" to log out.'
          )
        }
        else{
          const shouldLogout = window.alert(
            'Error fetching user information. Please log out and log back in. Click "OK" to log out.'
          );
        }
        this.appComponent.logout();
      },
    });
  }

  // New Method: Add Welcome Message
  setWelcomeMessage() {
    this.welcomeMessage = `Hello ${this.userFirstName}!`;
  }

  isUserAllowed(): boolean {
    return this.allowedUsers.includes(this.userName);
  }

}



