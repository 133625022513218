
<div class="container">
    <!-- Header -->
    <div class="header">
        <h1>Contract AI</h1>
    </div>

    <!-- Chat Window -->
    <div class="chat-window" id="chatWindow">
        <div class="message bot-message">
            WARNING: This application is under development. Please confirm all answers with the language in the Collective Bargaining Agreement.
            <a href="assets/prompt-engineering-guide.pdf" target="_blank" class="guide-button">
                View Prompt Engineering Guide
            </a>
        </div>
        <!-- <div class="message bot-message">Welcome to Contract AI! What Contract related question can I help you with?</div> -->
        <div class="message bot-message">
            {{ welcomeMessage }}
        </div>
        <!-- Messages will be appended here dynamically -->
        <div *ngFor="let msg of messages; let i = index">
            <div class="message" [ngClass]="{'user-message': msg.isUser, 'bot-message': !msg.isUser}">
                <ng-container *ngIf="msg.text === 'loading'; else normalMessage">
                    <div class="loading-dots">
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                    </div>
                </ng-container>
                <ng-template #normalMessage>
                    <!-- Display clickable abbreviated titles -->
                    <div *ngIf="msg.isAbbreviatedTitle; else normalText" (click)="openModal(msg)">
                        <button class="abbreviated-title-button">{{ msg.text }}</button>
                    </div>
                    <ng-template #normalText>
                        <div [innerHTML]="msg.text | newlineToBr"></div>
                    </ng-template>                
                </ng-template>
            </div>
                <div *ngIf="!msg.isUser && i === mostRecentBotMessageIndex && showFeedback" class="feedback">
                    <button (click)="sendFeedback(true)">👍</button>
                    <button (click)="showCommentSection()">👎</button>
                    <button (click)="showScoreForm()">🤔</button>
                </div>
                <div *ngIf="i === mostRecentBotMessageIndex && !msg.isUser" class="message reminder-message">
                    Reminder: Please refresh your browser if your next question is unrelated to the one you just asked. This will help with response accuracy.
                </div>
                
            
                <!-- Comment Section -->
                <div *ngIf="showComment && i === mostRecentBotMessageIndex" class="feedback-form">
                    <textarea [(ngModel)]="comment" placeholder="What about this response did you find unhelpful?" rows="3"></textarea>
                    <button (click)="submitComment()">Submit Comment</button>
                </div>

                <div *ngIf="showScore && i === mostRecentBotMessageIndex" class="score-form">
                    <label for="score">Correctness Score (1-10):</label>
                    <input
                        type="number"
                        id="score"
                        [(ngModel)]="score"
                        min="0"
                        max="10"
                        placeholder="Enter a score"
                        (change)="validateScore()"
                    />
                    <p *ngIf="scoreError" class="error">{{ scoreError }}</p>
                    <textarea
                        [(ngModel)]="comment" 
                        placeholder="Enter your comment"
                        rows="3">
                    </textarea>
                    <button (click)="sendFeedback(null, score)">Submit</button>
                </div>
                

        </div>
        
        <div *ngIf="specialBotResponse" class="message bot-message">
            Is this the question you would like to submit? Or would you like to make some changes?<br><br>
            {{ specialBotResponse }}
        </div>
        <div *ngIf="showSpecialButtons" class="special-buttons">
            <button (click)="handleSubmitSpecialResponse()">Submit</button>
            <button (click)="handleEditSpecialResponse()">Edit</button>
        </div>

        <div class="modal" *ngIf="showModal">
            <div class="modal-content">
                <button class="close-btn" (click)="closeModal()">X</button>
                <h2>{{ modalData.abbreviated_title }}</h2>
                <h3>{{ modalData.title }}</h3>
                <p>{{ modalData.content }}</p>
            </div>
        </div>
    </div>



    <!-- Form LOOK AT THIS -->
    <form class="form" (ngSubmit)="onSubmit()" #form="ngForm">
        <textarea 
            name="query" 
            placeholder="Type your question here" 
            [(ngModel)]="query" 
            #queryInput="ngModel"
            rows="1"
            required
            (input)="autoResize($event)"
            (keydown.enter)="onSubmit()">
        </textarea>
        <button type="submit" [disabled]="form.invalid">Submit</button>
        <button *ngIf="isUserAllowed()" type="button" (click)="onSpecialSubmit()">
            Special Submit
          </button>
    </form>

    
</div>
