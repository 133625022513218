import { Component, OnInit, HostListener } from '@angular/core';
import { MsalService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import { filter } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { NgxSpinnerService } from "ngx-spinner";

interface Message {
  text: string;
  isUser: boolean;
  isAbbreviatedTitle?: boolean;
  title?: string;
  content?: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {
  query: string = '';
  response: string = '';
  questionDisplay: string = '';
  showFeedback: boolean = false;
  showComment: boolean = false;
  comment: string = '';
  origin = environment.apiOrigin
  loginDisplay = false
  messages: Message[] = []; // Updated type
  specialBotResponse: string = ``;
  showSpecialButtons: boolean = false;
  mostRecentBotMessageIndex: number | null = null;
  matchingObjects: any[] = [];
  showModal: boolean = false; // Modal visibility
  modalData: any = {}; // Data for the modal display
  showScore: boolean = false;
  score: number | null = null;
  scoreComment: string = '';
  scoreError: string | null = null;
  userFirstName: string = '';
  userName: string = '';
  welcomeMessage: string = '';
  allowedUsers: string[] = ['Thomas Lowrey', 'Matthew Monahan', 'Kandi Doming', 'Brenda Burkham', 'Jeanna Christopher', 'Ryne Ray', 'Jana Fuller', 'Belstephen JeDai', 'Maria Almanza', 'Lexi Taylor', 'Cathy Cox']

  

  constructor(private http: HttpClient, 
    private msalBroadcastService: MsalBroadcastService, 
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        console.log(result);
      });
      this.http.get(`${this.origin}/clear`, { responseType: 'text' }).subscribe({
        next: (response) => console.log('Success:', response),
        error: (err) => console.error('Error:', err)
      });

      this.fetchUserInfo();
  }


  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: any) {
    // Send a request to clear the conversation history on the server when the page is refreshed
    this.http.get('/clear').subscribe();
  }

  onSubmit(event?: Event) {
    this.specialBotResponse = '';
    if (event) {
      event.preventDefault(); // Prevent default Enter key behavior
    }
    const query = this.query;
    this.showFeedback = false;
    this.messages.push({ text: query, isUser: true });
    this.messages.push({ text: 'loading', isUser: false });
    this.spinner.show();
    const body = new URLSearchParams({ query: this.query });
    this.query = '';

    // Sending the query to the backend
    this.http.post<any>(`${this.origin}/question`, `${body}`, { responseType: 'json' })
      .subscribe(data => {
        this.messages.pop(); // Remove loading message
        this.spinner.hide();
        
        // Set the main response
        this.response = data.response;
        this.messages.push({ text: this.response, isUser: false });
        this.mostRecentBotMessageIndex = this.messages.length - 1;
        
        // Store and display `abbreviated_title` from matching objects
        this.matchingObjects = data.matching_objects;
        this.displayAbbreviatedTitles();

        // Reset query input
        //this.query = '';
        this.showFeedback = true;
        // Optional: Scroll to the bottom of the chat window
        // const chatWindow = document.getElementById('chatWindow');
        // if (chatWindow) {
        //     chatWindow.scrollTop = chatWindow.scrollHeight;
        // }

      });
  }

  onSpecialSubmit(): void {
    console.log('Special Submit button clicked by:', this.userName);
    this.specialBotResponse = '';
    const query = this.query;
    this.showFeedback = false;
    // this.messages.push({ text: query, isUser: true });
    this.messages.push({ text: 'loading', isUser: false });
    this.spinner.show();
    const body = new URLSearchParams({ query: this.query });
    this.query = '';

    // Sending query to backend
    this.http.post<any>(`${this.origin}/question-assist`, `${body}`, { responseType: 'json' })
      .subscribe(data => {
        this.messages.pop(); // Remove loading message
        this.spinner.hide();
        
        // Set the main response
        this.specialBotResponse = data.response[0];
        this.showSpecialButtons = true;
        
        // Store and display `abbreviated_title` from matching objects

        // Reset query input
        //this.query = '';
        this.showFeedback = false;
      });

  }

  handleSubmitSpecialResponse(): void {
    this.query = this.specialBotResponse; // Set the query to the special response
    this.onSubmit(); // Trigger the onSubmit function
    this.clearSpecialResponse(); // Clear the special response and buttons
  }

  handleEditSpecialResponse(): void {
    this.query = this.specialBotResponse; // Paste the response into the textarea
    this.clearSpecialResponse(); // Clear the special response and buttons
  }

  clearSpecialResponse(): void {
    this.specialBotResponse = ''; // Clear the response
    this.showSpecialButtons = false; // Hide the buttons
  }



  // Method to open modal with matching object data
  openModal(matchingObject: any) {
    console.log('Modal opened for:', matchingObject); // Debugging line
    this.modalData = matchingObject;
    this.showModal = true;
  }

  // Method to close modal
  closeModal() {
      this.showModal = false;
      this.modalData = {};
  }

  displayAbbreviatedTitles(): void {
    this.matchingObjects.forEach(obj => {
        if (obj.abbreviated_title) {
            this.messages.push({
                text: obj.abbreviated_title,
                isUser: false,
                isAbbreviatedTitle: true, // Mark as abbreviated title for clickable functionality
                title: obj.title,
                content: obj.content
            });
        }
    });
    this.mostRecentBotMessageIndex = this.messages.length - 1;
  } 


  sendFeedback(isPositive: boolean | null = null, score: number | null = null) {
    // Validate score if it's provided
    if (score !== null && (score < 0 || score > 10)) {
        alert('Score must be between 0 and 10.');
        return; // Stop execution if the score is invalid
    }

    this.spinner.show();
    const mostRecentUserMessage = this.messages.slice().reverse().find(msg => msg.isUser)?.text || '';

    const feedbackType = isPositive === null ? 'thinking' : (isPositive ? '1' : '0');
    const body = new URLSearchParams({
        query: mostRecentUserMessage,
        feedback: feedbackType,
        comment: this.comment, // Shared comment variable
        ...(score !== null && { score: score.toString() }) // Include score if applicable
    });

    this.http.post(`${this.origin}/feedback`, `${body}`, { responseType: 'text' })
        .subscribe(() => {
            this.spinner.hide();
            alert('Feedback received! Thank you.');
            this.showFeedback = false;
            this.showComment = false;
            this.comment = ''; // Reset the shared comment variable
            this.showScore = false;
            this.score = null;
        }, error => {
            this.spinner.hide();
            console.error('Error sending feedback:', error);
            alert('Error sending feedback. Please try again.');
        });
  }




  showCommentSection() {
    this.showComment = true;
    this.showFeedback = false;
  }

  submitComment() {
    this.sendFeedback(false);
  }

  autoResize(textareaEvent: Event) {
    const textarea = textareaEvent.target as HTMLTextAreaElement;
    if (textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = textarea.scrollHeight + 'px';
    }
  }

  ///////////////////////////////
  // Method to show the score form
  showScoreForm() {
    this.showScore = true;
    this.scoreError = null; // Clear any previous errors
  }

  // Validate the score input
  validateScore() {
    if (this.score === null || this.score < 0 || this.score > 10) {
        this.scoreError = 'Score must be a number between 1 and 10.';
    } else {
        this.scoreError = null; // Clear the error if the input is valid
    }
  }

  // Method to submit the score and comment
  submitScore() {
    this.validateScore();
    if (!this.scoreError) {
        const feedbackData = {
            score: this.score,
            comment: this.scoreComment,
        };
        console.log('Feedback submitted:', feedbackData);
        // Add logic to send feedbackData to the backend
        this.showScore = false; // Hide the form after submission
        this.score = null;
        this.scoreComment = '';
    }
  }

  // New Method: Fetch User Info
  fetchUserInfo() {
    this.http.get(`${this.origin}/user-info`).subscribe({
      next: (info: any) => {
        this.userFirstName = info.user_name.split(' ')[0]; // Extract first name
        this.userName = info.user_name
        this.setWelcomeMessage();
      },
      error: (err) => {
        console.error('Error fetching user info:', err);
        this.userFirstName = 'Guest'; // Fallback
        this.userName = 'Guest'
        this.setWelcomeMessage();
      },
    });
  }

  // New Method: Add Welcome Message
  setWelcomeMessage() {
    this.welcomeMessage = `Hello ${this.userFirstName}! Welcome to Contract AI. What Contract related question can I help you with?`;
  }

  isUserAllowed(): boolean {
    return this.allowedUsers.includes(this.userName);
  }

}



