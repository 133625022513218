import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenService } from '../service/token.service';
import { environment } from '../environments/environment';

@Injectable({
    providedIn:'root'
}
)
export class JwtInterceptor implements HttpInterceptor {
    constructor(private tokenService: TokenService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const accessToken = this.tokenService.getToken
        const isApiUrl = request.url.startsWith(environment.apiOrigin);
        if (accessToken && isApiUrl) {
            request = request.clone({
                setHeaders: { 
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type' : 'application/x-www-form-urlencoded'
                }
            });
        }
        return next.handle(request);
    }
}