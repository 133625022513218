import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import { Component, Inject, OnInit, HostListener } from '@angular/core';
import { filter, Subject, takeUntil } from 'rxjs';
import { TokenService } from '../../service/token.service';
import { HttpClient } from '@angular/common/http';
import { jwtDecode } from 'jwt-decode';
import { NgxSpinnerService } from 'ngx-spinner';

// Original code
// @Component({
//   selector: 'app-root',
//   templateUrl: './app.component.html',
//   styleUrls: ['./app.component.scss']
// })
// export class AppComponent implements OnInit {
//   title = 'Contratc AI';
//   isIframe = false;
//   loginDisplay = false;
//   private readonly _destroying$ = new Subject<void>();

//   constructor(private authService: MsalService,
//     private msalBroadcastService: MsalBroadcastService,
//     private http: HttpClient,
//     private tokenService: TokenService,
//     @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
//   ) { }


//   ngOnInit() {
//     this.isIframe = window !== window.parent && !window.opener;
//     this.msalBroadcastService.inProgress$
//       .pipe(
//         filter((status: InteractionStatus) => status === InteractionStatus.None),
//         takeUntil(this._destroying$)
//       )
//       .subscribe(() => {
//         this.setLoginDisplay();
//       });
//   }

//   @HostListener('window:beforeunload', ['$event'])
//   beforeUnloadHandler(event: any) {
//     // Send a request to clear the conversation history on server when the page is refreshed
//     this.http.get('/clear').subscribe();
//   }

//   login() {
//     this.authService.loginRedirect();
//   }

//   logout() {
//     if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
//       this.authService.logoutPopup({
//         postLogoutRedirectUri: "/",
//         mainWindowRedirectUri: "/"
//       });
//     } else {
//       this.authService.logoutRedirect({
//         postLogoutRedirectUri: "/",
//       });
//     }
//   }

//   setLoginDisplay() {
//     this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
//     this.tokenService.setAccessToken()
//   }

//   ngOnDestroy(): void {
//     this._destroying$.next(undefined);
//     this._destroying$.complete();
//   }
// }

//user info
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Contract AI';
  isIframe = false;
  loginDisplay = false;
  userName: string | null = null;
  private readonly _destroying$ = new Subject<void>();

  isDarkMode = true;

  toggleTheme(): void {
    this.isDarkMode = !this.isDarkMode;
    const bodyClassList = document.body.classList;
    if (this.isDarkMode) {
      bodyClassList.remove('light-mode');
      bodyClassList.add('dark-mode');
    } else {
      bodyClassList.remove('dark-mode');
      bodyClassList.add('light-mode');
    }
  }

  constructor(
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private tokenService: TokenService,
    private spinner: NgxSpinnerService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
  ) {}

  ngOnInit() {
    // Show the spinner
    this.spinner.show();
    this.isIframe = window !== window.parent && !window.opener;
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });

    // Subscribe to user info changes
    this.tokenService.getUserInfo.subscribe((info) => {
      if (info) {
        this.userName = info.user_name;
        this.spinner.hide()
      }
    });
  }

  // Refresh error trial
  // @HostListener('window:beforeunload', ['$event'])
  // beforeUnloadHandler(event: any) {
  //   this.authService.logoutRedirect({ postLogoutRedirectUri: '/' });
  //   // this.http.get('/clear').subscribe(); // Clears history without logging out
  // }

  login() {
    this.authService.loginRedirect();
  }

  logout() {

    this.spinner.show()
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        postLogoutRedirectUri: '/',
        mainWindowRedirectUri: '/',
      });
    } else {
      this.authService.logoutRedirect({ postLogoutRedirectUri: '/' });
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    this.tokenService.setAccessToken();
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}

